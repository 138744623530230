<template>
  <BTabs
    lazy
    @activate-tab="setTabStage"
  >
    <BTab
      v-for="tab in visibleTabList"
      :key="tab.key"
      :title="tab.title"
      :active="tabStage === tab.key"
    >
      <slot :name="`tab(${tab.key})`" />
    </BTab>
  </BTabs>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api"
import {
  BTabs,
  BTab,
} from "bootstrap-vue"
import router from "@/router"

export default defineComponent({
  components: {
    BTabs,
    BTab,
  },
  props: {
    tabList: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const actionCashe = {}
    const tabStage = ref()
    const visibleTabList = computed(() => (props.tabList ?? []).filter(tab => tab.visible !== false))

    const setTabStage = tabIndex => {
      const tab = visibleTabList.value[tabIndex]
      if (tab == null) return
      tabStage.value = tab.key
      router.replace({
        query: {
          tab: tabStage.value,
        },
      }).catch(() => { })
      if (tab.action != null && !actionCashe[tab.key]) {
        tab.action()
        actionCashe[tab.key] = true
      }
    }

    onMounted(() => {
      const queryTab = router.history.current.query.tab
      const firstTab = visibleTabList.value[0]?.key
      const initTab = queryTab ?? firstTab
      const tabIndex = visibleTabList.value.findIndex(t => t.key === initTab)
      setTabStage(tabIndex)
    })

    return {
      tabStage,
      setTabStage,
      visibleTabList,
    }
  },
})
</script>
