<template>
  <BButton
    :variant="variant"
    :block="block"
    :disabled="isSaving || !isValid"
    @click="$emit('click')"
  >
    <BSpinner
      v-if="isSaving"
      small
    />
    {{ text }}
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BButton,
    BSpinner,
  },
  props: {
    isSaving: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: true,
    },
  },
  setup() {

  },
})
</script>
