<template>
  <BCard>
    <BRow>
      <BCol>
        <BFormGroup>
          <BFormTags
            v-model="state.infIdxList"
            placeholder="Input influencer idx"
            invalid-tag-text="You must enter only numbers"
            :tag-validator="tag => !isNaN(tag)"
            @input="getTargetCount"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTags,
} from 'bootstrap-vue'
import state from '../../state'
import useDetail from '../../useDetail'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTags,
  },
  setup() {
    const {
      getTargetCount,
    } = useDetail()

    return {
      state,
      getTargetCount,
    }
  },
})
</script>
