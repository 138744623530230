<template>
  <BCard>
    <BRow class="mb-1">
      <BCol>
        target count: {{ state.targetCount }}
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="USE FILTER">
          <BFormCheckbox
            v-model="state.isVisibleFilter"
            switch
            inline
            @input="getTargetCount"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <div v-if="state.isVisibleFilter">
      <BRow>
        <BCol>
          <BFormGroup label="PURPOSE">
            <BFormRadioGroup
              :checked="state.purposeSelected"
              :options="purposeOptions"
              @input="inputSelectedValue('purposeSelected', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="GENDER">
            <BFormCheckboxGroup
              :checked="state.genderSelected"
              :options="genderOptions"
              @input="inputSelectedValue('genderSelected', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="SNS">
            <BFormCheckboxGroup
              :checked="state.serviceSelected"
              :options="serviceOptions"
              @input="inputSelectedValue('serviceSelected', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="AGE">
            <BFormCheckboxGroup
              :checked="state.ageSelected"
              :options="ageOptions"
              @input="inputSelectedValue('ageSelected', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="REGION">
            <BFormCheckboxGroup
              :checked="state.regionSelected"
              :options="regionOptions"
              @input="inputSelectedValue('regionSelected', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="FOLLOWER">
            <BFormCheckboxGroup
              :checked="state.followerSelected"
              :options="followerOptions"
              @input="inputSelectedValue('followerSelected', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="AVG REACH">
            <BFormCheckboxGroup
              :checked="state.avgReachSelected"
              :options="avgReachOptions"
              @input="inputSelectedValue('avgReachSelected', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="스토어 판매 발생 내역 존재 여부">
            <BFormCheckbox
              v-model="state.hasMyshopSales"
              @input="getTargetCount"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </div>
  </BCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue"
import state from "../../state"
import useDetail from "../../useDetail"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormInvalidFeedback,
  },
  setup() {
    const purposeOptions = [
      { text: "CAMPAIGN", value: "CAMPAIGN" },
      { text: "NOTICE", value: "NOTICE" },
      { text: "COMMERCIAL", value: "COMMERCIAL" },
    ]
    const genderOptions = [
      { text: "전체", value: "ALL" },
      { text: "남성", value: "M" },
      { text: "여성", value: "F" },
    ]
    const serviceOptions = [
      { text: "전체", value: "ALL" },
      { text: "INSTAGRAM", value: "INSTAGRAM" },
      { text: "NAVER BLOG", value: "NAVER_BLOG" },
      { text: "YOUTUBE", value: "YOUTUBE" },
      { text: "TIKTOK", value: "TIKTOK" },
      { text: "연결끊김", value: "INACTIVE" },
      { text: "연결이력없음", value: "NOT" },
    ]

    const ageOptions = [
      { text: "전체", value: "ALL" },
      { text: "10대", value: "10~19" },
      { text: "20대", value: "20~29" },
      { text: "30대", value: "30~39" },
      { text: "40대", value: "40~49" },
      { text: "50대 이상", value: "50~" },
    ]

    const regionOptions = [
      { text: "전체", value: "ALL" },
      { text: "인천", value: "인천" },
      { text: "경기", value: "경기" },
      { text: "서울", value: "서울" },
      { text: "대구", value: "대구" },
      { text: "부산", value: "부산" },
      { text: "울산", value: "울산" },
      { text: "경상", value: "경상" },
      { text: "대전", value: "대전" },
      { text: "충청", value: "충청" },
      { text: "광주", value: "광주" },
      { text: "강원", value: "강원" },
      { text: "전라", value: "전라" },
      { text: "세종", value: "세종" },
      { text: "제주", value: "제주" },
      { text: "배송지 없음", value: "NOT" },
    ]

    const followerOptions = [
      { text: "전체", value: "ALL" },
      { text: "~1만", value: "~10000" },
      { text: "1만~5만", value: "10000~50000" },
      { text: "5만~10만", value: "50000~100000" },
      { text: "10만 이상", value: "100000~" },
    ]

    const avgReachOptions = [
      { text: "전체", value: "ALL" },
      { text: "~1천", value: "~1000" },
      { text: "1천~5천", value: "1000~5000" },
      { text: "5천~1만", value: "5000~10000" },
      { text: "1만~5만", value: "10000~50000" },
      { text: "5만 이상", value: "50000~" },
    ]

    const {
      getTargetCount,
    } = useDetail()

    const inputSelectedValue = (name, selectedValueList) => {
      const { length } = selectedValueList
      if (length === 0) {
        state[name] = ["ALL"]
      } else if (length === 1) {
        state[name] = selectedValueList
      } else if (selectedValueList[0] === "ALL") {
        state[name] = selectedValueList.slice(1)
      } else if (selectedValueList[length - 1] === "ALL") {
        state[name] = ["ALL"]
      } else {
        state[name] = selectedValueList
      }
      getTargetCount()
    }

    return {
      state,
      purposeOptions,
      genderOptions,
      serviceOptions,
      ageOptions,
      regionOptions,
      followerOptions,
      avgReachOptions,
      inputSelectedValue,
      getTargetCount,
    }
  },
})
</script>
