import moment from "moment"

export default function () {
  const resolveDate = value => (value != null && moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : null)
  const resolveDateTime = value => (value != null && moment(value).isValid() ? moment(value).format("YYYY-MM-DD HH:mm:ss") : null)
  const resolveUtcDateTime = value => (value != null && moment(value).isValid() ? moment(value).utc().format("YYYY-MM-DD HH:mm:ss") : null)
  const resolveMoney = value => value?.toLocaleString()
  const resolveUtcStartDateTime = value => (value != null && moment(value).isValid() ? moment(value).startOf("date").utc().format("YYYY-MM-DD HH:mm:ss") : null)
  const resolveUtcEndDateTime = value => (value != null && moment(value).isValid() ? moment(value).endOf("date").utc().format("YYYY-MM-DD HH:mm:ss") : null)
  const resolveUtcStartDateTimeTz = value => (value != null && moment(value).isValid() ? moment(value).startOf("date").utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null)
  const resolveUtcEndDateTimeTz = value => (value != null && moment(value).isValid() ? moment(value).endOf("date").utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null)
  const resolveUtcDateTimeTz = value => (value != null && moment(value).isValid() ? moment(value).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null)
  const resolveAddress = (address, addressDetail) => {
    if (!addressDetail) {
      return address
    }
    return `${address} ${addressDetail}`
  }
  return {
    resolveDate,
    resolveDateTime,
    resolveUtcDateTime,
    resolveMoney,
    resolveUtcStartDateTime,
    resolveUtcEndDateTime,
    resolveUtcStartDateTimeTz,
    resolveUtcEndDateTimeTz,
    resolveUtcDateTimeTz,
    resolveAddress,
  }
}
