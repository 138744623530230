<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        sm="6"
      >
        <BFormGroup label="LANDING PAGE">
          <TagbySelect
            :value="state.landingTypeSelected"
            :options="landingTypeOptions"
            :reduce="opt => opt.value"
            @input="inputLandingType"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
      >
        <BFormGroup
          v-if="state.landingTypeSelected === 'WEB'"
          label="URL"
          invalid-feedback="This is a required input value"
        >
          <BFormInput
            v-model="state.optionText"
            placeholder="https://"
            trim
            :state="getters.isValidOptionText"
          />
        </BFormGroup>
        <BFormGroup
          v-else-if="state.landingTypeSelected === 'CAMPAIGN_DETAIL'"
          label="CAMPAIGN IDX"
          invalid-feedback="This is a required input value"
        >
          <BFormInput
            v-model="state.optionText"
            placeholder="ex) 432"
            trim
            number
            type="number"
            :state="getters.isValidOptionText"
          />
        </BFormGroup>
        <BFormGroup
          v-else-if="state.landingTypeSelected === 'PRODUCT_DETAIL'"
          label="PRODUCT NO"
          invalid-feedback="This is a required input value"
        >
          <BFormInput
            v-model="state.optionText"
            placeholder="ex) P20231205034817T23R1"
            trim
            :state="getters.isValidOptionText"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup
          label="MESSAGE"
          invalid-feedback="This is a required input value"
        >
          <BFormTextarea
            v-model="state.message"
            :state="getters.isValidMessage"
            rows="3"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup>
          <BFormCheckbox
            v-model="state.rightNow"
            switch
            inline
          >
            right now
          </BFormCheckbox>
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow v-if="!state.rightNow">
      <BCol>
        <BFormGroup
          label="RESERVATION TIME"
          invalid-feedback="Can only be set from 9:30 to 20:30"
          :state="getters.isValidSendAt"
        >
          <TagbyDatetimeInputV2
            v-model="state.sendAt"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
            :state="getters.isValidSendAt"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    TagbySelect,
    BFormCheckbox,
    TagbyDatetimeInputV2,
  },
  setup() {
    const landingTypeOptions = [
      { label: '홈', value: 'HOME' },
      { label: '웹 링크', value: 'WEB' },
      { label: '캠페인 상세', value: 'CAMPAIGN_DETAIL' },
      { label: '최신 등록된 판매상품', value: 'RECENT_PRODUCT' },
      { label: '판매 인기 상품', value: 'BEST_PRODUCT' },
      { label: '상품 상세', value: 'PRODUCT_DETAIL' },
    ]

    const inputLandingType = value => {
      state.landingTypeSelected = value
      state.optionText = null
    }

    return {
      state,
      getters,
      landingTypeOptions,
      inputLandingType,
    }
  },
})
</script>
