<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(filter)>
          <FilterCard />
          <InfCard />
        </template>

        <template #tab(body)>
          <MessageCard />
        </template>

        <template #tab(etc)>
          <EtcCard />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV2
        v-if="!getters.isCreatingMode"
        title=""
      />
    </template>

    <template #hidden>
      <ErrorReasonModal />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { getRouterIdx } from '@/utils/router'
import TagbyTabs from '@/components/TagbyTabs.vue'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import ActionCard from './components/ActionCard/Main.vue'
import FilterCard from './components/FilterCard/Main.vue'
import InfCard from './components/InfCard/Main.vue'
import MessageCard from './components/MessageCard/Main.vue'
import EtcCard from './components/EtcCard/Main.vue'
import TagbyTimelinesV2 from '@/components/TagbyTimelinesV2.vue'
import useDetail from './useDetail'
import ErrorReasonModal from './components/Modals/ErrorReasonModal.vue'
import state from './state'
import getters from './getters'
import { resetState } from './useState'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    FilterCard,
    InfCard,
    MessageCard,
    EtcCard,
    TagbyTimelinesV2,
    ErrorReasonModal,
  },
  setup() {
    resetState()

    state.idx = getRouterIdx()

    const {
      getDetail,
      getTargetCount,
    } = useDetail()

    if (!getters.isCreatingMode) {
      getDetail()
    } else {
      getTargetCount()
    }

    const tabList = [
      { key: 'filter', title: 'FILTER' },
      { key: 'body', title: 'BODY' },
      { key: 'etc', title: 'ETC', visible: !getters.isCreatingMode },
    ]

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>
