<template>
  <div>
    <slot name="error" />
    <BAlert
      variant="danger"
      :show="hasLoadError"
    >
      <h4 class="alert-heading">
        Error fetching data
      </h4>
      <div class="alert-body">
        No detail found with this id. Check list for others.
      </div>
    </BAlert>
    <BRow v-if="!hasLoadError">
      <BCol
        cols="12"
        sm="9"
      >
        <slot name="content" />
      </BCol>
      <BCol
        cols="12"
        sm="3"
      >
        <slot name="side" />
      </BCol>
    </BRow>
    <slot name="hidden" />
    <slot name="modal" />
    <slot name="sidebar" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BAlert,
  },
  props: {
    hasLoadError: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

  },
})
</script>
