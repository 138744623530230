<template>
  <TagbyDangerModal
    v-model="state.isVisibleErrorReasonModal"
    title="Error reason"
    ok-only
    okTitle="ok"
  >
    <Prism
      :code="state.errorReason"
      style="max-height: 300px"
    />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyDangerModal,
    Prism,
  },
  setup() {
    return {
      state,
    }
  },
})
</script>
