import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function () {
  const toast = useToast()
  const makeToast = (variant, title, text) => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        text,
        variant,
        icon: 'AlertTriangleIcon',
      },
    })
  }
  return {
    makeToast,
  }
}
