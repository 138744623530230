<template>
  <BCard :title="title">
    <AppTimeline>
      <AppTimelineItem
        v-for="timeline in timelines"
        :key="timeline.idx"
        :title="resolveTitle(timeline)"
        :subtitle="timeline.text"
        :time="resolveDateTime(timeline.created_at)"
        variant="success"
      />
    </AppTimeline>
  </BCard>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useResolveValue from '@/utils/resolveValue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    title: {
      type: String,
      default: '타임라인',
    },
    timelines: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const resolveTitle = timeline => {
      if (timeline.admin == null) {
        return timeline.title
      }
      return `${timeline.title} (${timeline.admin.name})`
    }
    const { resolveDateTime } = useResolveValue()

    return {
      resolveTitle,
      resolveDateTime,
    }
  },
}
</script>

<style>

</style>
