import axios from '@axios'

export default (method, endPoint, responseType = null, hasCtx = false, asyncServer = false, xapi = false) => {
  const apiHost = asyncServer ? process.env.VUE_APP_ASYNC_BACKEND_SERVER : process.env.VUE_APP_BACKEND_SERVER
  if (hasCtx) {
    if (method.toLowerCase() === 'get') {
      return (ctx, params) => new Promise((resolve, reject) => {
        axios
          .get(`${apiHost}${endPoint}`, { params, responseType, headers: (xapi ? ({ 'X-Api-Version': 'v2' }) : {}) })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    if (method.toLowerCase() === 'post') {
      return (ctx, params) => new Promise((resolve, reject) => {
        axios
          .post(`${apiHost}${endPoint}`, params, { responseType, headers: (xapi ? ({ 'X-Api-Version': 'v2' }) : {}) })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    throw Error()
  }
  if (method.toLowerCase() === 'get') {
    return params => new Promise((resolve, reject) => {
      axios
        .get(`${apiHost}${endPoint}`, { params, responseType, headers: (xapi ? ({ 'X-Api-Version': 'v2' }) : {}) })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  if (method.toLowerCase() === 'post') {
    return params => new Promise((resolve, reject) => {
      axios
        .post(`${apiHost}${endPoint}`, params, { responseType, headers: (xapi ? ({ 'X-Api-Version': 'v2' }) : {}) })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  throw Error()
}
