<template>
  <BCard>
    <BRow>
      <BCol
        cols="6"
        sm="12"
        lg="6"
      >
        <slot name="left" />
      </BCol>

      <BCol
        cols="6"
        sm="12"
        lg="6"
      >
        <slot name="right" />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
  },
  setup() {

  },
})
</script>
