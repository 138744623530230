<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        sm="6"
      >
        <BFormGroup label="STATE">
          <TagbySelect
            v-model="state.state"
            :options="stateOptions"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol
        cols="12"
        sm="4"
      >
        <BFormGroup label="ADMIN NAME">
          <BFormInput
            :value="state.admName"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="8"
      >
        <BFormGroup label="ADMIN EMAIL">
          <BFormInput
            :value="state.admEmail"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbySelect,
  },
  setup() {
    const stateOptions = [
      'WAIT',
      'SENDING',
      'SENT',
      'CANCEL',
    ]

    return {
      state,
      stateOptions,
    }
  },
})
</script>
