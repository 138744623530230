<template>
  <VSelect
    :value="value"
    :options="options"
    :reduce="reduce"
    :multiple="multiple"
    :state="state"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import VSelect from "vue-select"

export default defineComponent({
  components: {
    VSelect,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Object, Number, Array],
    },
    options: {
      type: Array,
    },
    reduce: {
      type: Function,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
