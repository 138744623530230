export default () => ({
  hasLoadError: false,
  idx: null,
  message: null,
  landingPath: null,
  targetCondition: null,
  targetCount: null,
  state: null,
  admIdx: null,
  sendAt: null,
  createdAt: null,
  isSaving: false,
  isVisibleFilter: true,
  genderSelected: ['ALL'],
  serviceSelected: ['ALL'],
  ageSelected: ['ALL'],
  regionSelected: ['ALL'],
  followerSelected: ['ALL'],
  avgReachSelected: ['ALL'],
  hasMyshopSales: false,
  infIdxList: [],
  landingTypeSelected: 'HOME',
  optionText: null,
  rightNow: false,
  admName: null,
  admEmail: null,
  timelineList: [],
  snapshot: null,
  isVisibleErrorReasonModal: false,
  errorReason: null,
  purposeSelected: 'CAMPAIGN',
})
