import getStateOrigin from './stateOrigin'
import state from './state'

export const resetState = () => {
  const stateOrigin = getStateOrigin()
  Object.keys(stateOrigin).forEach(name => {
    state[name] = stateOrigin[name]
  })
}

export const others = () => { }
