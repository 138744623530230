import {
  computed,
  reactive,
} from '@vue/composition-api'

import moment from 'moment'

import state from './state'

const parsedInfIdxList = computed(() => state.infIdxList.map(idx => parseInt(idx, 10)))
const isCreatingMode = computed(() => !state.idx)
const isValidOptionText = computed(() => Boolean(state.optionText))
const isValidMessage = computed(() => Boolean(state.message))
const isValidSendAt = computed(() => {
  const value = state.sendAt
  const availableStartTime = moment(value).set({ hour: 9, minute: 30 })
  const availableEndTime = moment(value).set({ hour: 20, minute: 30 })
  const inputedTime = moment(value)
  return (
    inputedTime.isSameOrAfter(availableStartTime)
    && inputedTime.isSameOrBefore(availableEndTime)
  )
})

export default reactive({
  parsedInfIdxList,
  isCreatingMode,
  isValidOptionText,
  isValidMessage,
  isValidSendAt,
})
