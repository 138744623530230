import moment from 'moment'
import useToast from '@/utils/toast'
import useReloveValue from '@/utils/resolveValue'
import router from '@/router'
import state from './state'
import getters from './getters'
import api from './api'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    resolveUtcDateTime,
  } = useReloveValue()

  const resolveLandingPath = () => {
    if (state.landingPath.push_type === 'home') {
      state.landingTypeSelected = 'HOME'
    } else if (state.landingPath.push_type === 'web') {
      state.landingTypeSelected = 'WEB'
      state.optionText = state.landingPath.url
    } else if (state.landingPath.push_type === 'campaign') {
      state.landingTypeSelected = 'CAMPAIGN_DETAIL'
      state.optionText = state.landingPath.param?.[0]?.campaign_index
    } else if (state.landingPath.push_type === 'recent_product') {
      state.landingTypeSelected = 'RECENT_PRODUCT'
    } else if (state.landingPath.push_type === 'best_product') {
      state.landingTypeSelected = 'BEST_PRODUCT'
    } else if (state.landingPath.push_type === 'product_detail') {
      state.landingTypeSelected = 'PRODUCT_DETAIL'
      state.optionText = state.landingPath.param
    }
  }

  const resolveTargetCondition = () => {
    state.purpose = state.targetCondition.purpose ?? 'CAMPAIGN'

    if (state.targetCondition.idx != null) {
      state.infIdxList = state.targetCondition.idx
    }

    if (state.targetCondition.gender == null) {
      state.isVisibleFilter = false
    } else {
      state.genderSelected = state.targetCondition.gender
      state.serviceSelected = state.targetCondition.service
      state.ageSelected = state.targetCondition.age
      state.regionSelected = state.targetCondition.region
      state.followerSelected = state.targetCondition.follower
      state.avgReachSelected = state.targetCondition.avg_reach
      state.hasMyshopSales = state.targetCondition.has_myshop_sales
    }
  }

  const turnOnErrorReasonModal = err => {
    state.isVisibleErrorReasonModal = true
    if (err?.response?.data?.msg != null) {
      state.errorReason = err?.response?.data?.msg
    } else if (err?.response?.data != null) {
      state.errorReason = err?.response?.data
    } else {
      state.errorReason = err.toString()
    }
  }

  const getDetail = () => api.getDetail({
    idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.message = resData.message
    state.landingPath = resData.landing_path
    state.targetCondition = resData.target_condition
    state.targetCount = resData.target_count
    state.state = resData.state
    state.admIdx = resData.admin_idx
    state.sendAt = resData.send_at
    state.createdAt = resData.created_at
    state.admName = resData.adm_name
    state.admEmail = resData.adm_email
    resolveLandingPath()
    resolveTargetCondition()
    state.snapshot = resData
  }).catch(err => {
    console.log(err)
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const setSendAt = () => {
    if (state.rightNow) {
      state.sendAt = moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }

  const setTargetCondition = () => {
    const condition = {
      purpose: state.purposeSelected,
    }

    if (state.isVisibleFilter) {
      if (state.genderSelected.includes('ALL')) {
        condition.gender = ['M', 'F']
      } else {
        condition.gender = state.genderSelected
      }

      if (state.serviceSelected.includes('ALL')) {
        condition.service = [
          'INSTAGRAM', 'NAVER_BLOG', 'INACTIVE', 'NOT',
        ]
      } else {
        condition.service = state.serviceSelected
      }

      if (state.ageSelected.includes('ALL')) {
        condition.age = [
          '10~19', '20~29', '30~39',
          '40~49', '50~',
        ]
      } else {
        condition.age = state.ageSelected
      }

      if (state.regionSelected.includes('ALL')) {
        condition.region = [
          '인천',
          '경기',
          '서울',
          '대구',
          '부산',
          '울산',
          '경상',
          '대전',
          '충청',
          '광주',
          '강원',
          '전라',
          '세종',
          '제주',
          'NOT',
        ]
      } else {
        condition.region = state.regionSelected
      }

      if (state.followerSelected.includes('ALL')) {
        condition.follower = [
          '~10000',
          '10000~50000',
          '50000~100000',
          '100000~',
        ]
      } else {
        condition.follower = state.followerSelected
      }

      if (state.avgReachSelected.includes('ALL')) {
        condition.avg_reach = [
          '~1000',
          '1000~5000',
          '5000~10000',
          '10000~50000',
          '50000~',
        ]
      } else {
        condition.avg_reach = state.avgReachSelected
      }

      condition.has_myshop_sales = state.hasMyshopSales
    }
    if (getters.parsedInfIdxList.length > 0) {
      condition.idx = getters.parsedInfIdxList
    }
    state.targetCondition = condition
  }

  const getParams = () => {
    setSendAt()
    setTargetCondition()

    const params = {
      idx: state.idx,
    }
    if (state.message !== state.snapshot?.message) {
      params.message = state.message
    }
    if (state.state !== state.snapshot?.state) {
      params.state = state.state
    }
    if (resolveUtcDateTime(state.sendAt) !== resolveUtcDateTime(state.snapshot?.send_at)) {
      params.send_at = resolveUtcDateTime(state.sendAt)
    }
    params.landing_type = state.landingTypeSelected
    params.option_text = state.optionText

    if (JSON.stringify(state.targetCondition) !== JSON.stringify(state.snapshot?.target_condition)) {
      params.target_condition = JSON.stringify(state.targetCondition)
    }
    return params
  }

  const validateValues = () => {
    if (!state.purposeSelected) {
      throw Error('Purpose value is required')
    }
    if (!state.message) {
      throw Error('Message value is required')
    }
    if (!state.sendAt) {
      throw Error('SendAt value is required')
    }
    if (Object.keys(state.targetCondition).length === 0) {
      throw Error('Filter value is required')
    }
    if (
      ['WEB', 'CAMPAIGN_DETAIL'].includes(state.landingTypeSelected)
      && !state.optionText
    ) {
      throw Error('Option text is required')
    }
  }

  const updateAppPush = () => {
    state.isSaving = true
    const params = getParams()
    try {
      validateValues()
    } catch (err) {
      makeToast('danger', 'Fail to save', err.message)
      state.isSaving = false
      return
    }
    api.updateAppPush(params).then(() => {
      makeToast('primary', 'Save success')
    }).catch(err => {
      makeToast('danger', 'Save fail')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.isSaving = false
    })
  }

  const createAppPush = () => {
    state.isSaving = true
    const params = getParams()
    try {
      validateValues()
    } catch (err) {
      makeToast('danger', 'Fail to save', err.message)
      state.isSaving = false
      return
    }
    api.createAppPush(params).then(() => {
      makeToast('primary', 'Save success')
      router.push({
        name: 'message-app-push-list',
      })
    }).catch(err => {
      makeToast('danger', 'Save fail')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.isSaving = false
    })
  }

  const getTargetCount = () => {
    setTargetCondition()
    return api.getTargetCount(state.targetCondition).then(response => {
      state.targetCount = response.data.data.target_count
    }).catch(err => {
      turnOnErrorReasonModal(err)
      makeToast('danger', 'Fail to get target count')
    })
  }

  return {
    getDetail,
    updateAppPush,
    createAppPush,
    getTargetCount,
  }
}
